<template>
	<div class="configDiawer">
		<div
			class="proConfig"
			:class="{ open: drawer }"
			title="项目配置"
			:style="{ right: `${drawer ? drawerWidth : 0}px`, zIndex: zIndex }"
			@click="toggleOpenDiawer"
		>
			<i class="el-icon-setting" v-if="!drawer"></i>
			<i class="el-icon-circle-close" v-else></i>
		</div>
		<el-drawer
			title="项目配置"
			:visible.sync="drawer"
			:direction="direction"
			:size="drawerWidth"
			:before-close="handleClose"
			:show-close="false"
		>
			<div class="configDiawer-main">
				<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
					<el-tab-pane label="基础配置" name="base">
						<base-config></base-config>
					</el-tab-pane>
					<el-tab-pane label="页面配置" name="views">
						<view-config></view-config>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import tool from "@/utils/tool";
	import BaseConfig from "./components/base.vue";
	import ViewConfig from "./components/views.vue";
	const defaultConfig = {
		title: "",
		navMode: "default",
		layoutMode: "navFull",
	};
	export default {
		name: "ConfigDiawer",
		components: { ViewConfig, BaseConfig },
		data() {
			return {
				drawer: false,
				drawerWidth: 500,
				direction: "rtl",
				defaultConfig,
				activeName: "views",
				defaultProps: {
					children: "children",
					label: "label",
				},
				zIndex: 2004,
				currentRoutes: [],
			};
		},

		mounted() {
			// tool.fileDisplay("./src/views", (res) => {
			// 	console.log(res);
			// });
			// console.log(arrToTree(this.routes));
		},
		methods: {
			handleClose(done) {
				// this.$confirm("确认关闭？")
				// 	.then((_) => {
				done();
				// })
				// .catch((_) => {});
			},

			toggleOpenDiawer() {
				this.drawer = !this.drawer;
				if (this.drawer) {
					this.zIndex += 2;
				}
			},

			handleClick() {},
		},
	};
</script>

<style lang="scss" scoped>
	.configDiawer {
		&-main {
			padding: 0 20px;
			height: 100%;
			.el-tabs {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
			::v-deep .el-tabs__content {
				flex: 1;
			}
			::v-deep .el-tab-pane {
				height: 100%;
			}
		}
	}
</style>