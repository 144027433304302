<template>
  <div class="viewNav flex">
    <el-tag
      v-for="(tag, index) in tags"
      class="viewNav-item"
      :class="{ checkedTag: currentName == tag.name }"
      :key="tag.name"
      size="small"
      :closable="tag.name !== 'positionMap'"
      @click="handleName(tag.name)"
      @close="delTags(index)"
    >
      <em v-if="currentName == tag.name"></em>
      <span>
        {{ tag.label }}
      </span>
    </el-tag>
  </div>
</template>

<script>
export default {
  name: "ViewNav",
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    currentName() {
      return this.$route.name;
    },
  },
  watch: {
    $route: {
      handler(nValue, oValue) {
        let index = this.tags.findIndex((f) => f.name == nValue.name);
        if (index == -1) {
          this.tags.push({
            label: this.$t(`routes.${nValue.name}`) || nValue.meta.title,
            name: nValue.name,
            query: nValue.query,
            params: nValue.params,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleName(val) {
      if (val != this.$route.name) {
        this.$router.push({
          name: val,
        });
      }
    },
    delTags(index) {
      if (this.tags.length > 1) {
        if (this.tags.length - 1 == index) {
          this.handleName(this.tags[index - 1].name);
        }
        this.tags.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.viewNav {
  height: 40px;
  background: #eeffee;
  align-items: flex-end;
  box-shadow: 0px 2px 4px 4px rgba($color: #efefef, $alpha: 1);
  padding: 0 10px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &-item {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: #eeffee;
    border-color: #eeffee;
    color: $main_color;
	border: 1px solid #45733e;
    & + .viewNav-item {
      margin-left: 10px;
    }
    &::v-deep i {
      color: #367739;
      background-color: transparent;
    }
  }
  &::v-deep .el-tag {
    cursor: pointer;
  }
  .checkedTag {
    color: #367739;
    background: #ffffff;
    position: relative;
    // padding-left: 22px;
    // & em {
    // 	display: inline-block;
    // 	position: absolute;
    // 	left: 8px;
    // 	top: 50%;
    // 	transform: translateY(-50%);
    // 	width: 6px;
    // 	height: 6px;
    // 	background: #ffffff;
    // 	margin-right: 8px;
    // 	border-radius: 50%;
    // }
    &::v-deep i {
      color: #367739;
      background-color: transparent;
    }
  }
}
</style>