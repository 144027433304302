<template>
  <el-dialog
    title="更换皮肤"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-table :data="tableData">
      <el-table-column prop="label" label="名称" width="100" align="center">
        <template slot-scope="{ row }">
          <span v-if="isDisabled">
            {{ row.label }}
          </span>
          <el-input
            v-else
            v-model="row.label"
            placeholder="请输入名称"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="topColor"
        label="顶部字体颜色"
        width="180"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-color-picker
            :disabled="isDisabled"
            v-model="row.topColor"
          ></el-color-picker>
        </template>
      </el-table-column>
      <el-table-column label="顶部背景色" width="200" align="center">
        <template slot-scope="{ row }">
          <div class="colorBox">
            <div class="box flex">
              <p class="flex">
                <el-color-picker
                  :disabled="isDisabled"
                  v-model="row.topBg[0]"
                ></el-color-picker>
              </p>
              <p class="flex">
                <el-color-picker
                  :disabled="isDisabled"
                  v-model="row.topBg[1]"
                ></el-color-picker>
              </p>
            </div>
            <div
              class="box"
              :style="`background: linear-gradient(90deg, ${row.topBg[0]} 0%, ${row.topBg[1]} 100%);`"
            ></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="侧边字体颜色" width="180" align="center">
        <template slot-scope="{ row }">
          <el-color-picker
            :disabled="isDisabled"
            v-model="row.sideColor"
          ></el-color-picker>
        </template>
      </el-table-column>
      <el-table-column
        prop="textColor"
        label="侧边背景色"
        width="200"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="colorBox">
            <div class="box flex">
              <p class="flex">
                <el-color-picker
                  :disabled="isDisabled"
                  v-model="row.sideBg[0]"
                ></el-color-picker>
              </p>
              <p class="flex">
                <el-color-picker
                  :disabled="isDisabled"
                  v-model="row.sideBg[1]"
                ></el-color-picker>
              </p>
            </div>
            <div
              class="box"
              :style="`background: linear-gradient(90deg, ${row.sideBg[0]} 0%, ${row.sideBg[1]} 100%);`"
            ></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        min-width="200"
      >
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            icon="el-icon-check"
            v-if="row.checked"
            size="mini"
          >
            当前选中
          </el-button>
          <el-button v-else size="mini"> 更换 </el-button>
          <el-button size="mini" v-if="!isEdit" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" v-else type="success" @click="handleEdit(row)">
            保存</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      isDisabled: true,
      isEdit: false,
      currentRow: null,
    };
  },
  watch: {
    dataList: {
      deep: true,
      handler(val) {
        this.tableData = this.$tool.clone(val);
      },
      immediate: true,
    },
    show: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("skin", {
      show: "skinShow",
      dataList: "skinList",
    }),
  },
  methods: {
    ...mapMutations("skin", {
      toggleShowSkin: "TOGGLE_SHOW_SKIN",
      setSkinList: "SET_SKIN_LIST",
    }),
    handleClose(done) {
      if (this.isEdit) {
        this.$messageBox
          .confirm("确认不保留更改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then((res) => {
            this.toggleShowSkin();
          })
          .catch((err) => {});
      } else {
        this.toggleShowSkin();
      }
    },
    handleEdit(val) {
      if (this.isEdit) {
        this.setSkinList(this.tableData);
        this.isEdit = false;
        this.currentRow = null;
        this.isDisabled = true;
      } else {
        this.currentRow = val;
        this.isEdit = true;
        this.isDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.colorBox {
  .box {
    justify-content: space-between;
    height: 40px;
    & + .box {
      margin-top: 10px;
      border: 1px solid #efefef;
    }
    & p {
      & + p {
        margin-left: 10px;
      }
    }
  }
}
</style>