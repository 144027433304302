<template>
	<div class="viewConfig">
		<div class="table">
			<el-table
				:data="currentRoutes"
				:row-key="handleRowKey"
				border
				size="mini"
				default-expand-all
				:tree-props="{ children: 'children' }"
			>
				<el-table-column prop="name" min-width="100" label="文件夹/文件名称">
					<template slot-scope="{ row }">
						<span class="dot" :class="{ dot__new: row.isNew }">
							{{ row.name }}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="label" label="别名" min-width="100" />
				<el-table-column label="操作" width="100">
					<template slot-scope="{ row }">
						<el-tooltip
							class="item"
							effect="dark"
							content="新增文件"
							placement="top-start"
						>
							<el-button
								type="primary"
								size="mini"
								v-if="row.isFolder"
								circle
								@click="handlerEdit(row)"
								icon="el-icon-document-add"
							/>
						</el-tooltip>
						<el-tooltip
							class="item"
							effect="dark"
							content="删除"
							placement="top-start"
						>
							<el-button
								v-if="row.isNew"
								type="danger"
								size="mini"
								@click="handlerDelete(row)"
								icon="el-icon-delete"
								circle
							/>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<p>注意：复制或生成完路由需重新登录才生效！！！</p>
		</div>
		<div class="addFolder">
			<el-tooltip
				class="item"
				effect="dark"
				content="新增文件夹"
				placement="top-start"
			>
				<el-button
					type="success"
					size="mini"
					icon="el-icon-folder-add"
					circle
					@click="handlerEdit()"
				/>
			</el-tooltip>
		</div>
		<div class="viewConfig-btns">
			<el-button type="primary" size="small" round @click="saveRoutesConfig"
				>生成路由配置</el-button
			>
			<el-button type="success" size="small" round @click="saveFileConfig"
				>复制页面配置</el-button
			>
		</div>
		<edit-file
			:show="editShow"
			:row-info="rowInfo"
			@close="handlerShowEdit"
			@save="handlerSave"
		></edit-file>
	</div>
</template>

<script>
	import { arrToTree } from "@/router/tools/generateRouter.js";
	import editFile from "./editFile.vue";
	import tool from "../../../../utils/tool";
	export default {
		components: { editFile },
		name: "ViewConfig",
		data() {
			return {
				currentRoutes: [],
				newRoutes: [],
				rowInfo: {},
				editShow: false,
			};
		},
		computed: {
			routes() {
				return [...this.$store.getters.routes, ...this.newRoutes];
			},
		},
		watch: {
			routes: {
				handler(val) {
					// console.log(val);
					// this.currentRoutes = val;
					console.log(val);
					this.currentRoutes = this.generateFileTree(
						tool.clone(arrToTree(val, "route"))
					);
					console.log(this.currentRoutes);
				},
				deep: true,
				immediate: true,
			},
		},
		methods: {
			generateFileTree(arr, level = 1) {
				let res = [];
				arr.forEach((item) => {
					let temp = {
						name: item.route,
						label: item.name,
						isFolder: true,
					};
					if (item.isNew) {
						temp.isNew = true;
					}
					if (item.children && item.children.length) {
						temp.children = this.generateFileTree(item.children, level + 1).map(
							(_item) => {
								_item.pid = item.route;
								if (item.isNew) {
									_item.isNew = true;
								}
								return _item;
							}
						);
						let first = {
							name: "index",
							isFolder: false,
							label: item.name,
							isNew: item.isNew || false,
							pid: item.route,
						};
						if (item.isNew) {
							first.isNew = true;
						}
						// if (!temp.children.length) {
						temp.children.unshift(first);
						// }
					} else {
						let first = {
							name: "index",
							isFolder: false,
							label: item.name,
							isNew: item.isNew || false,
							pid: item.route,
						};
						if (item.isNew) {
							first.isNew = true;
						}
						if (level == 1) {
							temp.children = [first];
						} else {
							temp.isFolder = false;
						}
					}
					res.push(temp);
				});
				return res.sort((a, b) => a.name.localeCompare(b.name));
			},
			generateFileDir(arr, level = 1) {
				let res = [];
				arr.forEach((item) => {
					let temp = {
						name: item.route,
						isFolder: true,
					};
					if (item.children && item.children.length) {
						temp.children = this.generateFileDir(item.children);
					} else {
						temp.isFolder = false;
						temp.pid = item.pid;
					}
					res.push(temp);
				});
				return res.sort((a, b) => a.name.localeCompare(b.name));
			},
			handlerSave(temp) {
				temp.isNew = true;
				this.newRoutes.push(tool.clone(temp));
			},
			handlerEdit(e) {
				this.rowInfo = e;
				this.handlerShowEdit();
			},
			handlerDelete(e) {
				let index = this.newRoutes.findIndex((f) => f.name == e.route);
				// console.log(this.newRoutes);
				this.newRoutes.splice(index, 1);
			},
			handlerShowEdit() {
				this.editShow = !this.editShow;
			},
			handleRowKey(e) {
				return !e.isFolder ? `${e.pid}_${e.name}` : e.name;
			},
			handlerOutData() {
				return this.newRoutes.map((f) => {
					let temp = tool.clone(f);
					delete temp.isNew;
					return temp;
				});
			},
			handlerFileConfig(data) {
				let res = {};
				let hasPid = [];
				data.forEach((item) => {
					if (!item.pid) {
						res[item.route] = {
							isFolder: true,
							name: item.route,
							children: [
								{
									name: "index",
									pid: item.route,
								},
							],
						};
					} else {
						hasPid.push(item);
					}
				});
				hasPid.forEach((item) => {
					if (res[item.pid]) {
						res[item.pid].children.push({
							name: item.route,
							pid: item.pid,
						});
					} else {
						res[item.pid] = {
							pid: item.pid,
							name: item.route,
							isFolder: false,
						};
					}
				});
				return Object.values(res);
			},
			saveRoutesConfig(e) {
				const data = this.handlerOutData(this.newRoutes);
				console.log(data);
			},
			saveFileConfig(e) {
				const data = this.handlerFileConfig(this.handlerOutData(this.newRoutes));
				console.log(data);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.viewConfig {
		position: relative;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		padding-bottom: 50px;
		&-btns {
			position: absolute;
			left: 0;
			width: 100%;
			height: 50px;
			bottom: 0;
			z-index: 50;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
		.addFolder {
			position: absolute;
			right: 8px;
			top: 8px;
			z-index: 1;
		}
		.el-button--mini.is-circle {
			padding: 4px;
		}
	}
	.dot {
		position: relative;
		&::before {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: scale(0.8) translateY(-50%);
			color: #ff0000;
			font-size: 12px;
			z-index: 5;
			width: 28px;
			line-height: 23px;
		}
	}
	.dot__new {
		&::before {
			content: "new";
		}
	}
</style>