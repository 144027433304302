<template>
	<el-form
		ref="form"
		:model="defaultConfig"
		label-width="100px"
		label-position="top"
	>
		<el-form-item label="项目名称：">
			<el-input v-model="defaultConfig.title"></el-input>
		</el-form-item>
		<el-form-item label="导航栏模式：">
			<el-radio-group v-model="defaultConfig.navMode">
				<el-radio-button label="default"> 只有侧边 </el-radio-button>
				<el-radio-button label="sideTop"> 侧边顶部 </el-radio-button>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="布局模式：">
			<el-radio-group v-model="defaultConfig.layoutMode">
				<el-radio-button label="sideFull"> 侧边通栏 </el-radio-button>
				<el-radio-button label="navFull"> 顶部通栏 </el-radio-button>
			</el-radio-group>
		</el-form-item>
	</el-form>
</template>

<script>
	import tool from "@/utils/tool";
	const defaultConfig = {
		title: "",
		navMode: "default",
		layoutMode: "navFull",
	};
	export default {
		name: "BaseConfig",
		data() {
			return {
				defaultConfig,
			};
		},
	};
</script>

<style>
</style>