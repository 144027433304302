<template>
	<!-- <div class="EditFile"> -->
	<el-dialog
		:title="rowInfo.name ? `新增文件至-${rowInfo.label}` : '新增文件夹'"
		:visible="show"
		width="400px"
		append-to-body
		:before-close="handleClose"
	>
		<el-form ref="form" label-width="auto" :model="form">
			<el-row :gutter="20">
				<el-col :span="24">
					<el-form-item label="路由别名：">
						<el-input
							v-model="form.name"
							placeholder="请输入路由别名(例如:首页)"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="路由名称：">
						<el-input
							v-model="form.route"
							placeholder="请输入路由名称(例如:home)"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24" v-if="rowInfo.name">
					<el-form-item label="父级路由：">
						<el-input
							disabled
							v-model="form.pid"
							placeholder="请输入父级路由(例如:home)"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="是否可见：">
						<el-switch
							v-model="form.hidden"
							:active-value="false"
							:inactive-value="true"
						></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="rowInfo.name">
					<el-form-item label="是否内页：">
						<el-switch v-model="form.isDetail"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="显示图标：">
						<el-input
							v-model="form.icon"
							placeholder="请输入显示(例如:icon-xxx)"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="路由排序：">
						<el-slider v-model="form.sort"></el-slider>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="toSave">确 定</el-button>
		</span>
	</el-dialog>
	<!-- </div> -->
</template>

<script>
	const defaultForm = {
		pid: "",
		route: "",
		icon: "",
		name: "",
		sort: 0,
		hidden: false,
		isDetail: false,
	};
	import tool from "@/utils/tool";
	export default {
		name: "EditFile",
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			rowInfo: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				dialogVisible: true,
				form: tool.clone(defaultForm),
			};
		},
		watch: {
			show(val) {
				if (val) {
					if (this.rowInfo.name) this.form.pid = this.rowInfo.name;
				} else {
					this.form = tool.clone(defaultForm);
				}
			},
		},
		methods: {
			handleClose(done) {
				this.$emit("close");
			},
			toSave() {
				console.log(this.form);
				this.$emit("save", tool.clone(this.form));
				this.$emit("close");
				// console.log(this.form);
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>